<template>
    <template-base width="50">
        <h1 class="card-header">Digite sua senha novamente para continuar</h1>
        <!--<LoginForm ref="login" />-->
        <div class="card-body">
            <form @submit.prevent="login">
                <div class="form-group">
                    <label for="userPassword">Senha</label>
                    <input class="form-control tcs-input" id="userPassword" v-model="password" type="password" required
                        :disabled="isBusy" />
                </div>
                <!--<hr class="my-4" />-->
                <vue-recaptcha v-if="captcha" sitekey="6LcQWL8dAAAAAMS38bZsYq0JYTQSoHxm5wkqUR0i" @verify="verifica" ref="recaptcha" :loadRecaptchaScript="true"></vue-recaptcha>
                <div class="row align-items-center">
                    <transition name="fade">
                        <div class="col d-flex text-danger" v-if="error">
                            <x-circle-icon class="mr-2" />
                            <div>{{error}}</div>
                        </div>
                        <div class="col d-flex text-success" v-else-if="success">
                            <check-circle-icon class="mr-2" />
                            <div>Sucesso! Redirecionando...</div>
                        </div>
                    </transition>
                    <div class="col text-right">
                        <button type="submit" class="btn btn-success lg" v-if="!isBusy">
                            Verificar
                        </button>
                        <button type="submit" class="btn btn-success lg" disabled="disabled" v-else>
                            <span class="spinner-border x-spinner-border-sm" role="status" aria-hidden="true"></span>
                            &nbsp;Verificando...
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </template-base>
</template>
<script>
import TemplateBase from '../templates/Base'
import loginService from '../services/login';
import { wait } from '../helpers/common';
import VueRecaptcha from 'vue-recaptcha';

export default {
    components: {
        TemplateBase,
        VueRecaptcha,
    },
    data () {
        return {
            email: '',
            password: '',
            isBusy: false,
            error: '',
            success: false,
            reCaptchaResponse: '',
            captcha: process.env.NODE_ENV === 'production',
        }
    },
    created() {
        if (!this.$route.params.next) {
            this.$router.replace('/');
        }
        let user = loginService.getUser()
        if (user) {
            this.email = user.email;
        } else {
            this.$router.replace({ name: 'logout', params: { next: this.$route.params.next }});
        }
    },
    methods: {
        verifica(token) {
            this.reCaptchaResponse = token;
        },
        async login () {
            this.isBusy = true;
            this.error = '';
            this.success = false;
            try {
                if (!this.reCaptchaResponse && this.captcha) throw new Error('Por favor preencha o captcha.');
                let sucesso = await loginService.login(this.email, this.password, this.reCaptchaResponse);
                if (sucesso) {
                    this.success = true;
                    await wait(1000);
                    this.$router.replace(this.$route.params.next);
                    this.isBusy = false;
                } else {
                    throw new Error('Senha inválida');
                }
            } catch (e) {
                this.$refs.recaptcha.reset();
                this.reCaptchaResponse = '';
                this.isBusy = false;
                this.error = e.response?.data?.error || e.toString() || 'Erro desconhecido, por favor tente novamente.';
            }
        },
    },
};
</script>
<style scoped>
  .spinner-border.x-spinner-border-sm {
    width: 1.1rem;
    height: 1.1rem;
    border-width: 0.2em;
  }
</style>
